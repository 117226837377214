import * as React from "react";

import { useExperiment } from "@shared-ui/experiment-context";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkSheetTransition } from "@egds/react-core/sheet";

import {
  Map,
  PropertySummaryHeader,
  PropertyRelevantAmenities,
  ProductHighlights,
  PropertySummaryVipBadge,
  PropertySpaceOverview,
} from "@shared-ui/lodging-property-details";

import { IdentifierType, ProductRatingSummary } from "@shared-ui/retail-product-rating-summary";
import { PropertyFilteredReviewsDialog } from "components/shared/PropertyFilteredReviewsDialog/PropertyFilteredReviewsDialog";
import { PropertyStickyBookBar } from "@shared-ui/lodging-property-offers";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";

import { PropertySummaryProps } from "../typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigureAspectRatioType } from "@egds/react-core/images";

import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { RelevantContentModule } from "../components/product/RelevantContentWrapper";
import { AmenitiesModule } from "../components/product/AmenitiesWrapper";
import { CheckInOutModule } from "../components/product/CheckInOutWrapper";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { LocationSectionModule } from "../components/product/LocationSectionWrapper";

const WrappedSummaryHeader = PropertyBexApiWrapper(PropertySummaryHeader);
const WrappedStickyBookBar = PropertyBexApiWrapper(PropertyStickyBookBar);
const WrappedPropertyMap = PropertyBexApiWrapper(Map);
const WrapperPropertyRelevantAmenities = PropertyBexApiWrapper(PropertyRelevantAmenities);
const WrappedProductRatingSummary = ProductBexApiWrapper(ProductRatingSummary);
const WrappedPropertySummaryVipBadge = PropertyBexApiWrapper(PropertySummaryVipBadge);
const WrappedPropertySpaceOverview = PropertyBexApiWrapper(PropertySpaceOverview);

const PropertySummaryLocationHighlights: React.FC<PropertySummaryProps> = ({ context, templateComponent }) => {
  const id = templateComponent?.metadata?.id || "";
  const [isReviewsDialogOpen, reviewsDialogActions, ReviewsDialogComponent] = useDialog(`summary-reviews-${id}`);
  const [isMapDialogOpen, mapDialogActions, MapDialogComponent] = useDialog(`summary-map-${id}`);
  const triggerRef = React.useRef<HTMLElement>(null);
  const { bucket: isShowingModularisedSection } = useExperiment("PDP_MODULARISED_LOCATION_SECTION", true).exposure;
  const repositioningMapAndLocationInsightsToAboutTheAreaVariant =
    useExperiment("Repositioning_map_and_location_insights_to_About_the_area").exposure.bucket === 1;

  if (!templateComponent) {
    return null;
  }

  const productHighlightsInput = {
    productIdentifier: {
      id: `${context.searchContext.location.id}`,
      type: IdentifierType.PROPERTY_ID as any,
      travelSearchCriteria: {
        property: {
          primary: {
            dateRange: null,
            rooms: [{ adults: 2 }],
            destination: {
              regionId: String(context?.searchContext?.location?.parent?.id),
            },
          },
          secondary: {},
        },
      },
    },
  };
  const wrapWithLayoutFlex = (component: React.ReactChild) => (
    <UitkLayoutFlex space="two" justifyContent="space-between">
      <UitkLayoutFlexItem>{component}</UitkLayoutFlexItem>
      <UitkLayoutFlexItem>
        <div>
          <WrappedStickyBookBar
            context={context}
            clickTargetRef="startDate"
            priceSummaryJustifyContent="end"
            isSticky={false}
            hideCtaButton={hideCtaButton}
          />
        </div>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );

  const onClickReviews = reviewsDialogActions.openDialog;
  const onClickMap = mapDialogActions.openDialog;
  const { hideCtaButton, hideHeader, showVipBadge, showHighlights = true } = templateComponent.config;

  return (
    <>
      <UitkCard>
        <UitkSpacing margin={{ blockend: "three" }} padding={{ inline: "six", block: "six" }}>
          <UitkCardContentSection>
            <Viewport>
              <ViewSmall>
                <>
                  {!hideHeader && (
                    <UitkSpacing margin={{ blockend: "three" }}>
                      <div data-stid="content-hotel-title">
                        <WrappedSummaryHeader context={context} />
                      </div>
                    </UitkSpacing>
                  )}
                  <UitkSpacing margin={{ blockend: !hideHeader ? "six" : "two" }}>
                    <div>
                      <WrappedStickyBookBar
                        align="start"
                        priceSummaryJustifyContent="start"
                        context={context}
                        clickTargetRef="startDate"
                        isSticky={false}
                        hideCtaButton={hideCtaButton}
                      />
                    </div>
                  </UitkSpacing>
                  {showVipBadge && hideHeader && (
                    <UitkSpacing padding={{ blockend: "three" }}>
                      <div>
                        <WrappedPropertySummaryVipBadge context={context} />
                      </div>
                    </UitkSpacing>
                  )}
                  <UitkSpacing margin={{ blockend: "three" }}>
                    <WrappedProductRatingSummary context={context} onClickReviews={onClickReviews} />
                  </UitkSpacing>
                  {showHighlights && (
                    <ProductHighlights
                      inputs={productHighlightsInput}
                      onClickReviews={onClickReviews}
                      pageId="HOT.HIS.Dateless"
                    />
                  )}
                  <WrappedPropertySpaceOverview context={context} />
                  <UitkSpacing padding={{ blockend: "three" }}>
                    <div>
                      <AmenitiesModule context={context} />
                    </div>
                  </UitkSpacing>
                  <RelevantContentModule context={context} />
                  <div>
                    <CheckInOutModule context={context} />
                  </div>
                  {!repositioningMapAndLocationInsightsToAboutTheAreaVariant && (
                    <div data-testid="map-image-link">
                      <LocationSectionModule
                        context={context}
                        onClickMap={onClickMap}
                        aspectRatio={UitkFigureAspectRatioType.R21_9}
                      />
                    </div>
                  )}
                  <UitkSpacing margin={{ blockend: "three" }}>
                    <div>
                      <WrapperPropertyRelevantAmenities context={context} />
                    </div>
                  </UitkSpacing>
                </>
              </ViewSmall>
              <ViewLarge>
                <UitkLayoutGrid columns={12} space="three">
                  <UitkLayoutGridItem colSpan={isShowingModularisedSection ? 12 : 8}>
                    <div>
                      {!hideHeader && (
                        <UitkSpacing padding={{ blockend: "six" }}>
                          <div data-stid="content-hotel-title">
                            <WrappedSummaryHeader context={context} />
                          </div>
                        </UitkSpacing>
                      )}
                      {showVipBadge && hideHeader && (
                        <UitkSpacing padding={{ blockend: "three" }}>
                          <div>
                            <WrappedPropertySummaryVipBadge context={context} />
                          </div>
                        </UitkSpacing>
                      )}
                      <UitkSpacing padding={{ blockend: "four" }}>
                        {isShowingModularisedSection ? (
                          wrapWithLayoutFlex(
                            <WrappedProductRatingSummary context={context} onClickReviews={onClickReviews} />
                          )
                        ) : (
                          <WrappedProductRatingSummary context={context} onClickReviews={onClickReviews} />
                        )}
                      </UitkSpacing>
                      {showHighlights && (
                        <ProductHighlights
                          inputs={productHighlightsInput}
                          onClickReviews={onClickReviews}
                          pageId="HOT.HIS.Dateless"
                        />
                      )}
                      <WrappedPropertySpaceOverview context={context} />
                      <UitkSpacing padding={{ blockend: "three" }}>
                        <AmenitiesModule context={context} />
                      </UitkSpacing>
                      <RelevantContentModule context={context} />
                      <div>
                        <CheckInOutModule context={context} />
                      </div>
                      <UitkSpacing margin={{ blockstart: "four" }}>
                        <div>
                          <WrapperPropertyRelevantAmenities context={context} />
                        </div>
                      </UitkSpacing>
                    </div>
                  </UitkLayoutGridItem>
                  <UitkLayoutGridItem colSpan={isShowingModularisedSection ? 12 : 4}>
                    <div>
                      <UitkSpacing padding={{ blockend: "six" }}>
                        {!isShowingModularisedSection && (
                          <div>
                            <WrappedStickyBookBar
                              context={context}
                              clickTargetRef="startDate"
                              priceSummaryJustifyContent="end"
                              isSticky={false}
                              hideCtaButton={hideCtaButton}
                            />
                          </div>
                        )}
                      </UitkSpacing>
                      {!repositioningMapAndLocationInsightsToAboutTheAreaVariant && (
                        <div data-stid="map-image-link">
                          <LocationSectionModule
                            context={context}
                            onClickMap={onClickMap}
                            aspectRatio={UitkFigureAspectRatioType.R16_9}
                          />
                        </div>
                      )}
                    </div>
                  </UitkLayoutGridItem>
                </UitkLayoutGrid>
              </ViewLarge>
            </Viewport>
          </UitkCardContentSection>
        </UitkSpacing>
      </UitkCard>
      <UitkSheetTransition isVisible={isReviewsDialogOpen}>
        <ReviewsDialogComponent>
          <PropertyFilteredReviewsDialog context={context} onClose={reviewsDialogActions.closeDialog} />
        </ReviewsDialogComponent>
      </UitkSheetTransition>
      <UitkSheetTransition isVisible={isMapDialogOpen}>
        <MapDialogComponent>
          <WrappedPropertyMap context={context} onClose={mapDialogActions.closeDialog} triggerRef={triggerRef} />
        </MapDialogComponent>
      </UitkSheetTransition>
    </>
  );
};
export default PropertySummaryLocationHighlights;
